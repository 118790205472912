// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/shipping-methods/config.ts"
);
import.meta.hot.lastModified = "1731679026372.6436";
}
// REMIX HMR END

export const config = {
  path: '/dashboard/shipping-methods',
}
